<template>
  <component :is="tag" class="facility-reviews parent-bg">
    <div class="col col-ratings">
      <p v-if="blok.note && blok.base_rating">
        <span class="note bold size-l"
          ><em class="highlighted bold size-3xl">{{
            useFormattedNote(blok.note)
          }}</em>
          /{{ blok.base_rating }}</span
        >
        <span v-if="blok.description_note" class="size-2xs">{{
          useFormattedDescriptionNote(blok.description_note, blok.total_reviews)
        }}</span>
      </p>
      <SimpleButton
        v-if="blok.cta_url"
        :link="blok.cta_url"
        class="margin-xs-top"
        :xl="true"
        @click="
          trackingStore.sendTrackingData({
            event: 'click.action',
            click: useSnakeCaseFilter(
              useMicroCopy('facilityreviews.cta.label')
            ),
            click_chapter1: 'reviews',
            click_chapter2: 'CTA',
          })
        "
      >
        {{ useMicroCopy("facilityreviews.cta.label") }}
      </SimpleButton>
    </div>

    <div class="col col-reviews">
      <GenericCarousel
        v-if="blok.items?.length > 0"
        :items="blok.items"
        class="carousel-navigation-bottom"
        :class="{ lock: blok.items?.length === 1 }"
        :context="context"
      />
    </div>
    <component
      :is="'script'"
      v-if="useFormattedNote(blok.note) && blok.total_reviews"
      type="application/ld+json"
      inner-h-t-m-l="structuredDataSeo"
    />
  </component>
</template>

<script setup>
const trackingStore = useTrackingStore();
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  context: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok, context } = toRefs(props);
const structuredDataSeo = JSON.stringify({
  "@context": "https://schema.org/",
  "@type": "Organization",
  name: useMicroCopy("site.name"),
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: useFormattedNote(blok.value?.note),
    ratingCount: blok.value?.total_reviews,
  },
});
</script>

<style lang="scss">
.background-facility-reviews {
  .facility-reviews {
      @include pair-7;
    }

  &.alternate {
    .facility-reviews {
      @include pair-1;
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::before {
        background-color: var(--solid-01);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.facility-reviews {
  .col-ratings {
    grid-column: 2 / -2;

    @include for-desktop-up {
      grid-column: 3 / 10;
    }
  }

  .col-reviews {
    grid-column: 1 / -1;

    @include for-desktop-up {
      grid-column: 10 / -1;
      position: relative;
    }
  }

  .note {
    display: block;
    line-height: 100%;

    .highlighted {
      font-style: normal;
      color: var(--brand);
      line-height: 100%;
    }
  }

  :deep(.swiper-pagination) {
    @include for-desktop-up {
      justify-content: start;
      left: 1.12rem;
    }
  }
}
</style>
